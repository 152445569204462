import React, {useContext, useState} from "react";
import {PageLayout, InGameFooter, Logo, LoadingSpinner, PhoneInput, Markdown} from "../../components/components";
import useCurrentClueLogic from "../../hooks/useCurrentClueLogic";
import GameContext from "../../context/game-context";
import UserProfileContext from "../../context/user-profile-context";
import {AppAlertsContext} from "../../context/app-alerts-context";
import {Button, Form} from "react-bootstrap";
import {CONTACT_CLUEMASTER_METHODS} from "../../services/firestore/functions/twilio";
import {AlertType} from "../../components/providers/app-alerts/AppAlerts";
import appContent from "../../markdown/app-content";
import ApplyCustomGameTheme from "../../app/game/shared/apply-custom-game-theme/ApplyCustomGameTheme";
import useGameLogo from "../../hooks/useGameLogo";
import {contactClueMaster} from "../../services/cluemaster-contact/clustmaster-contact";

const TIME_TO_WAIT_BEFORE_CLOSING_TAB = 2500; // in ms

const ContactClueMaster = () => {
  const {game, team, isLoading: isLoadingGame} = useContext(GameContext);
  const {userProfile, isLoading: isLoadingUserProfile} = useContext(UserProfileContext);
  const {clue} = useCurrentClueLogic(game, team);
  const {popAlert, popError} = useContext(AppAlertsContext);
  const {finishedAllClues, penaltiesFromHints} = useCurrentClueLogic(game, team);
  const [phoneField, setPhoneField] = useState("");
  const [message, setMessage] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const {smallLogo} = useGameLogo()

  // Calculated Values
  const profileHasPhoneNumber = userProfile?.phoneNumber;
  const phoneNumber = userProfile?.phoneNumber || phoneField;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setSendingMessage(true);
      await contactClueMaster({
        team,
        currentClueName: clue?.name || "null",
        name: userProfile?.name || "unknown",
        phone: phoneNumber,
        message,
        hintsTaken: penaltiesFromHints.length,
        contactMethod: CONTACT_CLUEMASTER_METHODS.TWILIO_GROUP_US_ZAPIER_INTERNATIONAL,
      });
      popAlert(appContent.inGame.contactClueMaster.successfullySentMessage, AlertType.SUCCESS);
      setSendingMessage(false);
      setTimeout(() => {
        window.close();
      }, TIME_TO_WAIT_BEFORE_CLOSING_TAB);
    } catch (e) {
      console.log(e);
      popError(e.message);
      setSendingMessage(false);
    }
  };

  return <PageLayout footer={<InGameFooter gameId={game?.id} finishedGame={finishedAllClues}/>}>
    <LoadingSpinner isLoading={isLoadingGame || isLoadingUserProfile} hideChildrenWhenLoading>
      <LoadingSpinner isLoading={sendingMessage}>
        <Logo className="mb-5" src={smallLogo} style={{width: "100%"}}/>
        <Markdown>
          {appContent.inGame.contactClueMaster.markdown}
        </Markdown>
        <Form className="mb-5" onSubmit={handleSubmit}>
          {!profileHasPhoneNumber && (
            <Form.Group>
              <Form.Label>{appContent.inGame.contactClueMaster.phoneNumberLabel}</Form.Label>
              <PhoneInput value={phoneField} onChange={(number) => setPhoneField(number)}/>
            </Form.Group>
          )}
          <Form.Group controlId="contactCluemaster.message">
            <Form.Label>{appContent.inGame.contactClueMaster.messageLabel}</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              value={message}
              placeholder={appContent.inGame.contactClueMaster.messagePlaceholder}
              onChange={(e) => setMessage(e.target.value)}
              maxLength={1000}
              required
            />
          </Form.Group>
          <Button block type="submit" disabled={sendingMessage}>
            {appContent.inGame.contactClueMaster.submitText}
          </Button>
        </Form>
      </LoadingSpinner>
    </LoadingSpinner>
    <ApplyCustomGameTheme />
  </PageLayout>;
};

export default ContactClueMaster;
