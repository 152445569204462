import {CONTACT_CLUEMASTER_METHODS, twilioTextCluemaster} from "../firestore/functions/twilio";
import appContent from "../../markdown/app-content";
import firebaseApp from "../firebase-app";

export const contactClueMaster = async (args) => {
  const {team, currentClueName, name, phone, message, hintsTaken, contactMethod} = args;
  const contactData = {
    name: name,
    teamName: team?.name,
    clueName: currentClueName,
    hints: hintsTaken,
    message,
    phone,
  }

  if (!team) {
    throw Error("We had trouble identifying the team your on. Your message was not sent.");
  }

  if (!message) {
    throw Error("Missing message for contact Cluemaster");
  }

  if (contactMethod === CONTACT_CLUEMASTER_METHODS.TWILIO) {
    return twilioTextCluemaster(contactData);
  } else if (contactMethod === CONTACT_CLUEMASTER_METHODS.TWILIO_GROUP_US_ZAPIER_INTERNATIONAL) {
    const message = appContent.inGame.contactClueMaster.textToCluemasterZapier(contactData);
    return await firebaseApp.functions().httpsCallable("contactClueMasterGroupMessage")({
      gameId: team.gameId,
      teamId: team.id,
      message,
      phone,
    });
  } else if (contactMethod === CONTACT_CLUEMASTER_METHODS.ZAPIER) {
    const message = appContent.inGame.contactClueMaster.textToCluemasterZapier(contactData);
    return await firebaseApp.functions().httpsCallable("zapierContactClueMasterForHelp")({
      gameId: team.gameId,
      teamId: team.id,
      message,
      phone,
    });
  } else {
    throw Error(`Contact method ${contactMethod} is not implemented.`);
  }
};
